/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

:root {
    --mat-select-disabled-text-color-trigger: rgba(
        255,
        255,
        255,
        0.38
    ) !important;

    --mat-select-disabled-text-disabled-light: rgba(
        21,
        21,
        21,
        0.88
    ) !important;
}

.dark {
    .mat-mdc-select-disabled {
        color: var(--mat-select-disabled-text-color-trigger) !important;
    }
    .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
        color: var(--mat-select-disabled-text-color-trigger) !important;
    }
}

.light {
    .mat-mdc-select-disabled {
        color: var(--mat-select-disabled-text-disabled-light) !important;
    }
    .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
        color: var(--mat-select-disabled-text-disabled-light) !important;
    }
}
.cdk-pane-p-0 {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 0px !important;
    }
}
